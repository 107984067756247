<template>
  <div class="auth-form mx-auto width-narrow mb-5">
    <div class="card mx-auto mb-5">
      <div class="card-body">
        <h1 class="heading-bigger text-center mb-4">
          Password Reset
        </h1>
        <f-alert
          v-if="resetError"
          theme="danger"
          visible
          class="my-4">
          An error has occurred. Please try again.
        </f-alert>

        <f-alert
          v-if="resetSendSuccess"
          theme="success"
          visible
          class="my-4">
          {{ resetSentMsg }}
        </f-alert>

        <p class="text-center text-size-medium">
          Enter the <strong>e-mail address</strong> you used to register for FunFit.<br>We'll send you an e-mail with a link to reset your password.
        </p>

        <validation-observer
          ref="password-reset-form"
          tag="form">
          <validation-provider
            rules="required|email"
            name="email"
            v-slot="{ errors }">
            <div class="form-group">
              <!-- <label>E-mail</label> -->
              <input
                v-model="email"
                name="email"
                type="email"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="E-mail Address" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <f-button
            type="submit"
            theme="secondary"
            class="block mx-auto mt-3 text-uppercase"
            @click.prevent="validateForm"
            :disabled="submitting">
            Send
          </f-button>
        </validation-observer>

        <div class="text-center mt-4">
          <f-button
            theme="text"
            @click.native="$router.push('/login')"
            class="lnk lnk-big lnk-big-blue"
            :disabled="submitting">
            Back to login page
          </f-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'password-reset',
  data () {
    return {
      email: '',
      resetError: false,
      resetSendSuccess: false,
      resetSentMsg: 'An e-mail with a link to reset your password has been sent to You.',
      submitting: false
    }
  },
  mounted () {

  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['password-reset-form'], this.sendRequest);
    },
    sendRequest () {
      this.resetError = false;
      this.submitting = true;
      delete this.$http.defaults.headers['X-Csrf-Token'];

      this.$http.post('/password-recovery/send-request', {
        email: this.email
      }).then((response) => {
        this.submitting = false;
        if (response.data.success === 1) {
          this.resetSendSuccess = true;
        } else {
          this.resetError = true;
        }
      }).catch((error) => {
        console.log(error);
        this.submitting = false;
        this.resetError = true;
      });
    }
  }
}
</script>
